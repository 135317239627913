@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
$secondary-color:#8cbe4f;
body {
    background-color: #f4f7fc;
    font-family: 'Roboto', sans-serif;
}

.error{
    color:red;
    font-size: 11px;
    font-weight: 300;
}

*:focus{
    outline: none !important;
}

.font-9{
    font-size: 9px;
}

.font-10{
    font-size: 10px;
}

.font-11{
    font-size: 11px;
}

.font-12{
    font-size: 12px !important;
}

.font-14{
    font-size: 14px;
}


.font-16{
    font-size: 16px;
}


.font-18{
    font-size: 18px;
}


.font-20{
    font-size: 20px;
}


.font-24{
    font-size: 24px;
}

.light{
    font-weight: 300;
}

.regular{
    font-weight: 400;
}

.medium{
    font-weight: 500;
}

.bold{
    font-weight: 700;
}

.extraBold{
    font-weight: 900;
}

.minHeight80vh{
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.orangeFont{
    color: #fd7237;
}

.darkGreyFont{
    color:#767676;
}

.blackFont{
    color:#000;
}

.darkGreyBG{
    background: #a9a9a9;
}

.redBG{
    background: #ff6259;
}


.orangeBG{
    background: #fd7237;
}

.transparentBG{
 background: transparent;
}

.bg-black{
    background: #000;
}

.bg-darkGrey {
    background: #3f3f3f;
}

.width-30{
    width:30px;
}

.height-auto {
    height: auto;
}

.height-30{
    height:30px;
}

.width-20{
    width:20px;
}

.height-20{
    height:20px;
}

.userListStyle {
    background: #f8fbff;
    border-radius: 3px;
    box-shadow: 0px 0px 1px #767676;
}

.userListStyle:hover{
    background: #fff;
    box-shadow: 0px 0px 10px #e5e5e5;
}

.manageUserSelectBox{
        border-radius: 20px;
        background: #f8fbff;
        border: 1px solid #ededed;
}

.height-40{
    height:40px;
}

.height-50{
    height:50px;
}

.height-150{
    height:150px;
}

.borderRdius5{
    border-radius: 5px;
}

.borderRdius10{
    border-radius: 10px;
}

.borderRdius20{
    border-radius: 20px;
}

.inputBoxAddUser{
    border: 1px solid #dadada;
    box-shadow: 0px 0px 1px #e6e6e6;
    border-radius: 3px;
}

.borderBottom {
    border-bottom: 1px solid #dadada;
}

.borderBottomOrange {
    border-bottom: 3px solid #fd7237;
}

textarea {
    resize: none;
}

.pointer{
    cursor: pointer;
}

.height-80{
    height:80px;
}
.max-width-270 {
    max-width: 270px;
}
.legend{
    display: none;
}

g {
    transform: translate(62px, 65px);
}

g text {
    transform: translate(62px, 74px);
}

.displayNone{
    display: none;
}

.displayBlock {
    display: block;;
}

.text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.menuActive {
    background:#ccc !important;
}

.ngxSelectStyle .ui-dropdown {
    border-radius: 20px;
    background: #f8fbff;
    height: 30px;
}

.ngxSelectStyle .ui-dropdown.ui-dropdown-clearable .ui-dropdown-label {
    padding-right: 4em;
    height: 30px;
    padding-left: 15px;
    border-radius: 20px;
    background: #f8fbff;
    padding: 6px 4em 6px 12px;
    border: 1px solid #ededed;
    font-size: 12px;
}

.ngxSelectStyle .ui-dropdown .ui-dropdown-trigger {
    background-color: transparent;
    text-align: center;
    padding: 5px;
    color: #848484;
}

.ngxSelectStyle .ui-dropdown {
    background: #f8fbff;
    border: 0px solid transparent;
}

.clearfix {
    clear: both;
}    
.borderLeftQuotation {
    border-left: 1px solid #dadada;
}


.radio {
    margin: 10px 0 3px 0;
    display: block;
    cursor: pointer;
  }
  .radio input {
    display: none;
  }
  .radio input + span {
    line-height: 24px;
    height: 25px;
    padding-left: 22px;
    display: block;
    position: relative;
  }
  .radio input + span:not(:empty) {
    padding-left: 23px;
  }
  .radio input + span:before, .radio input + span:after {
    content: '';
    width: 16px;
    height: 16px;
    display: block;
    border-radius: 50%;
    left: 0;
    top: 3px;
    position: absolute;
  }
  .radio input + span:before {
    background: #D1D7E3;
    transition: background 0.2s ease, -webkit-transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
    transition: background 0.2s ease, transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
    transition: background 0.2s ease, transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2), -webkit-transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
  }
  .radio input + span:after {
    background: #fff;
    -webkit-transform: scale(0.78);
            transform: scale(0.78);
    transition: -webkit-transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
    transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
    transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4), -webkit-transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
  }
  .radio input:checked + span:before {
    -webkit-transform: scale(1.04);
            transform: scale(1.04);
    background: #ff6259;
  }
  .radio input:checked + span:after {
    -webkit-transform: scale(0.4);
            transform: scale(0.4);
    transition: -webkit-transform .3s ease;
    transition: transform .3s ease;
    transition: transform .3s ease, -webkit-transform .3s ease;
  }
  .radio:hover input + span:before {
    -webkit-transform: scale(0.92);
            transform: scale(0.92);
  }
  .radio:hover input + span:after {
    -webkit-transform: scale(0.74);
            transform: scale(0.74);
  }
  .radio:hover input:checked + span:after {
    -webkit-transform: scale(0.4);
            transform: scale(0.4);
  }

  .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background-color: #ff6259;
    box-shadow: 0px 0px 0px #d8d8d8;
    border: 0px solid #d8d8d8;
}
.nav-pills .nav-link {
    border-radius: 0;
    width: 150px;
    text-align: center;
    height: 34px;
    background: #f9fafe;
    box-shadow: 0px 0px 3px #d8d8d8;
    color: #000;
    margin-right: 30px;
    border: 1px solid #d8d8d8;
    padding: 6px;
}

.reportsTab .multiselect-dropdown .dropdown-btn {
    border: 1px solid #a1a1a1 !important;
    padding: 5px 8px !important;
    border-radius: 2px !important;
    height: 30px !important;
}

.reportsTab .multiselect-dropdown .dropdown-btn .dropdown-down, .siteMultiselect .multiselect-dropdown .dropdown-btn .dropdown-down , .multiselect-dropdown .dropdown-btn .dropdown-up  {
    border-left: 5px solid transparent !important;
    border-right: 5px solid transparent !important;
}

.reportsTab .multiselect-dropdown .dropdown-btn .dropdown-down, .siteMultiselect .multiselect-dropdown .dropdown-btn .dropdown-down {
      top: 0px !important;
}

.reportsTab .multiselect-dropdown .dropdown-btn .selected-item {
    max-width: 87px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.siteMultiselect .multiselect-dropdown .dropdown-btn .selected-item  {
    max-width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.siteMultiselect .multiselect-dropdown .dropdown-btn {
    border: 1px solid #dadada !important;
    box-shadow: 0px 0px 1px #acabab !important;
    border-radius: 3px;
    padding: 10px 8px !important;
    border-radius: 2px !important;
    height: 40px !important;
    background: #fff;
}

.addInput{
    border: 1px solid #acacac;
    border-radius: 1px;
    height: 25px;
}

.w-80{
    width:85% !important;
}

.popupBtn{
    height: 22px;
    width: 80px;
  }

  .submitBtn{
    height: 30px;
    width: 130px;
  }

  .transparentText{
      color:transparent;
  }

  .payslipTab .nav-pills{
      float: right;
      margin-top: -33px;
  }
  .uploadBox{
    border-radius: 10px;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 10px #d5d5d5db;
  }

  .excelUpload{
    border: 1px solid #ccc;
    border-radius: 7px;
  }
  #uploadexcel{
    opacity: 0;
}

.pagination {
    margin: 0 auto;
    width: fit-content;
}

.pagination .page-item.active .page-link {
    background-color: #fd7237;
    border-color: #fd7237;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.reportsTab .nav-pills .nav-link {
 width: auto;
 min-width: 150px;
}
.tg-list,
.tg-list li {
	list-style: none;
	margin: 0;
	padding: 0;
}

.tg-list {
	text-align: center;
	display: flex;
	align-items: center;
}

.tgl {
	display: none;
  

	& + .tgl-btn {
		box-sizing: border-box;
		&::selection {
			background: none;
		}
	}
  
	+ .tgl-btn {
    float: right;
		outline: 0;
		display: block;
		width: 3.5em;
		height: 1.5em;
		position: relative;
		cursor: pointer;
    user-select: none;
		&:after,
    &:before {
			position: relative;
			display: block;
			content: "";
			width: 38%;
			height: 100%;
		}
    
		&:after {
			left: 0;
		}
    
		&:before {
			display: none;
		}
	}
  
	&:checked + .tgl-btn:after {
		left: 58%;
	}
}

// themes
.tgl-light {
	+ .tgl-btn {
		background: #989898;
		border-radius: 2em;
		padding: 2px;
		transition: all .4s ease;
		&:after {
			border-radius: 50%;
			background: #fff;
			transition: all .2s ease;
		}
	}
  
	&:checked + .tgl-btn {
		background: #ff6259;
	}
}

.pagination .page-link {
    color: #fd7237;
}

.p-dropdown{
    height: 40px;
    align-items: center;
    &:not(.p-disabled).p-focus{
        box-shadow:none;
    }
    .p-dropdown-label{
        font-size: 12px;
    }
}
.custom-toast {
    .p-toast {
      z-index: 99;
      width: 23rem;
    }
  
    .p-toast-message-error {
      background-color: rgba(229, 65, 65, 1);
    }
  
    .p-toast-message-success {
      background-color: $secondary-color;
    }
  
    .p-toast-message-warn {
      background-color: #1cbdab;
    }
  
    .p-toast-message-info {
      background-color: #f9be1b;
    }
  
    .p-toast-message-error,
    .p-toast-message-success,
    .p-toast-message-warn,
    .p-toast-message-info {
      color: #fff;
      padding: 20px;
      border-radius: 8px;
  
      .p-toast-summary {
        font-size: 18px;
        padding-bottom: 3px;
      }
  
      .p-toast-detail {
        font-size: 14px;
      }
    }
  
    .p-toast-icon-close {
      border: 0;
      background-color: transparent;
      width: 16px;
      height: 16px;
      background-image: url(assets/img/close.png);
      background-size: contain;
      background-repeat: no-repeat;
      filter: invert(1);
  
      span {
        display: none;
      }
    }
  }
/********mobile*********/
@media only screen and (max-width: 767px) {
    .pagination .page-link {
        padding: .2rem .4rem;
        font-size: 12px;
    }
}
@media only screen and (max-width: 425px) {
    .sm-text-center{
        text-align: center;
    }
    
    aside{
        overflow-y: hidden !important;
    }
}